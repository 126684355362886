import apiBaseService from './apiBaseService'

import { useAuthStore } from '../../store/auth';
import { useNuxtStore } from '../../store/nuxt';

export default {
  mixins: [apiBaseService],
  data () {
      return {
        authStore: useAuthStore(),
        nuxtStore: useNuxtStore(),
      }
  },
  computed: {
    token () {
      return (this.authStore.token) ? this.authStore.token : '';
    },
    region () {
      return this.nuxtStore.locale;
    },
  },
  methods: {
    async login (pwdString) {
      return await this.apiRequest({
        method: 'POST',
        data: { pwdString },
        url: '/auth/partner-portal/access'
      })
    },
    async getToken (email) {
      return await this.apiRequest({
        method: 'POST',
        data: { email },
        url: '/auth/login/partner-email'
      })
    },
    async getUser (token) {
      return await this.apiRequest({
        method: 'GET',
        url: `/auth/token/getUser/${token}`
      })
    },
    async updateUserSetting (updatedSetting, id) {
      return await this.apiRequest({
        method: 'POST',
        headers: { authorization: this.token },
        url: `/auth/updateUserSettings/${id}`,
        data: updatedSetting,
      })
    },
    async updateUserListSettings (update) {
      return await this.apiRequest({
        method: 'POST',
        headers: { authorization: this.token },
        url: '/partner/list-settings',
        data: update,
      });
    },
    async updateUserLanguageSettings (update) {
      return await this.apiRequest({
        method: 'POST',
        headers: { authorization: this.token },
        url: '/partner/language-setting',
        data: update,
      });
    },
    async loginToPartnerAPI (userData) {
      let user = userData;
      if (typeof user === 'string') user = JSON.parse(userData);

      const { email: clientName, userId } = user;

      return await this.partnerApiRequest({
        method: 'POST',
        url: '/oauth/token',
        data: {
          clientName,
          userId,
          region: this.region,
        },
      });
    }
  }
}
